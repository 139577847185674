<template>
  <div class="status"></div>
</template>

<script>
import { getLoadUrl } from "@/request/api";
export default {
  name: "Home",
  data() {
    return {
      status: 0,
      token: ''
    };
  },
  components: {},
  created() {},
  mounted() {
    this.isMobile();
    this.token = this.$route.params.token;
    this.getLoadUrl();
  },
  methods: {
    // 添加判断方法
    isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      if (flag == null) {
        this.status = 0;
      } else {
        this.status = 1;
      }
    },
    getLoadUrl() {
        const param = {
            type: this.status,
            encrypt: this.token
        }
        getLoadUrl(param).then((res) => {
            console.log(res.data)
            window.location = res.data;
        })
    }
  },
};
</script>

<style scoped>
</style>
